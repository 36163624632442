import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import { GoogleMap } from '../../components/GoogleMap'
import { useMediaQuery } from 'react-responsive'

const number = '+54 9 3416 865085';

const WhatsApp = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const target = isMobile ? '_self' : '_blank';
  const href = `https://wa.me/${number.replace(/\s/g, '').replace('+', '')}`;
  return <a target={target} href={href}><i className="fa fa-whatsapp"></i> 3416 865085</a>;
}

const Email = () => {
  return <a href="mailto:contacto@colaboro.ar"><i className="fa fa-envelope-o"></i> contacto@colaboro.ar</a>;
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content columns">
              <div className="column is-4">
                <h1>Contacto</h1>
                <p>San Martín 3396, Rosario, Argentina</p>
                <p>Lunes a viernes de 8 a 20 hs</p>
                <p><WhatsApp /></p>
                <p><Email /></p>
                <div>
                  <GoogleMap address={{ lat: -32.976900, lng: -60.644402 }} googleMapsApiKey="AIzaSyDzwUsmwDvJ-wX73YXJ1sgMPHcmfvYfzdA" />
                </div>
              </div>
              <div className="column is-8">
                <h2>Envíanos tu consulta</h2>
                <form
                  name="contacto"
                  method="post"
                  action="/contacto/gracias/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contacto" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'name'}>
                      Tu nombre
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'name'}
                        onChange={this.handleChange}
                        id={'name'}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'email'}>
                      Email
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'email'}
                        name={'email'}
                        onChange={this.handleChange}
                        id={'email'}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'message'}>
                      Mensaje
                    </label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        name={'message'}
                        onChange={this.handleChange}
                        id={'message'}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <button className="button is-link" type="submit">
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
