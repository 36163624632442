import React from 'react';
import PropTypes from 'prop-types';
import marker from '../img/marker.png';

const Marker = ({ text, onClick }) => (
  <div
    alt={text}
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '35px',
      height: '35px',
      userSelect: 'none',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
      zIndex: 1
    }}
    role="link"
    tabIndex={0}
    onKeyDown={onClick}
  >
    <img alt="Marker" style={{ height: "100%", position: 'absolute', top: -20, left: 5 }} src={marker} />
  </div>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;