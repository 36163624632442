import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

const isClient = typeof window !== 'undefined';

export const GoogleMap = (props) => {
    const {
        address,
        googleMapsApiKey
    } = props;
    return (
        <section className="google-map">
            <div className="map" style={{ height: '50vh', width: '100%' }}>
                {isClient && (
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: googleMapsApiKey }}
                        defaultCenter={address}
                        defaultZoom={15}
                    >
                        <Marker
                            lat={address.lat}
                            lng={address.lng}
                            text="Colaboro Coworking"
                            onClick={() => window.open('https://goo.gl/maps/74jvkSQCmQ2Fiwpq9', "_blank")}
                        />
                    </GoogleMapReact>
                )}
            </div>
        </section>
    );
}